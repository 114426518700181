import { Field, LoadingComponent } from "ndr-designsystem";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { setFinalDecommissioningDate, setPreliminaryDecommissioningDate } from "./store/technicalResourcesSlice";
import { PageStatus } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const OperationTab = ({ pageStatus }: { pageStatus: PageStatus }): ReactElement => {
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");
    const { currentResource: resource } = useAppSelector(state => state.technicalResources);

    if (resource === null) {
        return <>
            Invalid resource id
        </>;
    }

    if (resource === undefined) {
        return <LoadingComponent/>
    }

    return (
        <>
            <Field
                onValueChange={val => dispatch(setPreliminaryDecommissioningDate(val))}
                inputType='boolean'
                isEditing={isEditing || isAdding}
                defaultValue={resource.preliminaryDecommissioningDate}
                title={t("technical_resources_details.preliminaryDecommissioningDate")}
            />
            <Field
                onValueChange={val => dispatch(setFinalDecommissioningDate(val))}
                inputType='boolean'
                isEditing={isEditing || isAdding}
                defaultValue={resource.finalDecommissioningDate}
                title={t("technical_resources_details.finalDecommissioningDate")}
            />
        </>
    )
}

export default OperationTab