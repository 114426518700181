import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TechnicalResource } from "../../../api/fixed/TechnicalResource/TechnicalResource";
import { TechnicalResourceType } from "../../../api/fixed/TechnicalResource/TechnicalResourceType";
import { MarketLocation } from "../../../api/fixed/MarketLocation/MarketLocation";
import { Tranche } from "../../../api/fixed/Tranche/Tranche";
import { TrancheSizeUnit } from "../../../api/fixed/Tranche/TrancheSizeUnit";
import { VoltageLevel } from "../../../api/fixed/MarketLocation/VoltageLevel";
import { VoltageTransformation } from "../../../api/fixed/MarketLocation/VoltageTransformation";
import { BillingModel } from "../../../api/fixed/TechnicalResource/BillingModel";

interface EditableTranche extends Tranche {
    inEdit: boolean
}

const WIND_POWER_INCREMENTS = 0.1;

export interface TechnicalResourcesState {
    allResources: TechnicalResource[] | undefined,
    currentResource: TechnicalResource | undefined | null
}

const initialState: TechnicalResourcesState = {
    allResources: undefined,
    currentResource: undefined
};

export const technicalResourcesSlice = createSlice({
    name: 'technicalResources',
    initialState,
    reducers: {
        setAllResources: (state, action: PayloadAction<TechnicalResource[] | undefined>) => {
            state.allResources = action.payload;
        },
        setCurrentResource: (state, action: PayloadAction<TechnicalResource | undefined | null>) => {
            state.currentResource = action.payload;
        },
        setName: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource) {
                state.currentResource.name = action.payload;
            }
        },
        resetCurrentResource: (state, action: PayloadAction<string>) => {
            state.currentResource = {
                ...state.allResources?.find(r => r.inventoryItemId === action.payload || r.externalID === action.payload)
            };
        },
        addResourceToArray: (state, action: PayloadAction<TechnicalResource>) => {
            state.allResources?.push(action.payload);
            state.currentResource = action.payload
        },
        setExternalID: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource) {
                state.currentResource.externalID = action.payload;
            }
        },
        setMarketMasterDataRegistryId: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource) {
                state.currentResource.marketMasterDataRegistryId = action.payload
            }
        },
        setType: (state, action: PayloadAction<TechnicalResourceType | undefined>) => {
            if (state.currentResource) {
                state.currentResource.technicalResourceType = action.payload
                if (action.payload?.code === "SSE" && !state.currentResource.consumesEnergy) {
                    state.currentResource.consumesEnergy = true;
                    state.currentResource.consumption = {};
                } else if (action.payload?.code === "SEE" && !state.currentResource.producesEnergy) {
                    state.currentResource.producesEnergy = true;
                    state.currentResource.production = {};
                }
            }
        },
        setPowerPlantCode: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource) {
                state.currentResource.powerPlantCode = action.payload
            }
        },
        setProduction: (state, action: PayloadAction<MarketLocation | undefined>) => {
            if (state.currentResource) {
                state.currentResource.production = action.payload
            }
        },
        setProductionAccountingGroup: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource && state.currentResource.production) {
                state.currentResource.production.accountingGroup = action.payload
            }
        },
        setProductionTrancheSupplierAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            const { index, value } = action.payload;
            if (state.currentResource && state.currentResource.production) {
                if (state.currentResource.production.tranches) {
                    state.currentResource.production.tranches[index].supplierId = value;
                }
            }
        },
        setProductionTranches: (state, action: PayloadAction<EditableTranche[] | Tranche[] | undefined>) => {
            if (state.currentResource && state.currentResource.production) {
                if (action.payload && action.payload.length > 0) {
                    state.currentResource.production.accountingGroup = undefined;
                    state.currentResource.production.supplierId = undefined;
                }
                state.currentResource.production.tranches = action.payload;
            }
        },
        updateNewProductionTrancheSupplier: (state, action: PayloadAction<{ value: string | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.production &&
                state.currentResource.production.tranches &&
                state.currentResource.production.tranches.length > 0) {
                state.currentResource.production.tranches[action.payload.index].supplierId = action.payload.value;
            }
        },
        updateNewProductionTrancheExternalID: (state, action: PayloadAction<{ value: string | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.production &&
                state.currentResource.production.tranches &&
                state.currentResource.production.tranches.length > 0) {
                state.currentResource.production.tranches[action.payload.index].externalID = action.payload.value;
            }
        },
        updateNewProductionTrancheAccountingGroup: (state, action: PayloadAction<{ value: string, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.production &&
                state.currentResource.production.tranches &&
                state.currentResource.production.tranches.length > 0) {
                state.currentResource.production.tranches[action.payload.index].accountingGroup = action.payload.value;
            }
        },
        updateNewProductionTrancheSizeUnit: (state, action: PayloadAction<{ value: TrancheSizeUnit | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.production &&
                state.currentResource.production.tranches &&
                state.currentResource.production.tranches.length > 0) {
                state.currentResource.production.tranches[action.payload.index].sizeUnit = action.payload.value;
            }
        },
        updateNewProductionTrancheSize: (state, action: PayloadAction<{ value: number, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.production &&
                state.currentResource.production.tranches &&
                state.currentResource.production.tranches.length > 0) {
                state.currentResource.production.tranches[action.payload.index].size = action.payload.value;
            }
        },
        setProductionVoltageLevel: (state, action: PayloadAction<VoltageLevel | undefined>) => {
            if (state.currentResource &&
                state.currentResource.production) {
                state.currentResource.production.voltageLevel = action.payload;
            }
        },
        setProductionVoltageTransformation: (state, action: PayloadAction<VoltageTransformation | undefined>) => {
            if (state.currentResource &&
                state.currentResource.production) {
                state.currentResource.production.voltageTransformation = action.payload;
            }
        },
        setProductionMeasurementLocations: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentResource &&
                state.currentResource.production) {
                state.currentResource.production.measurementLocations = action.payload;
            }
        },
        updateNewProductionMeasurementLocation: (state, action: PayloadAction<string>) => {
            if (state.currentResource && state.currentResource.production && state.currentResource.production.measurementLocations) {
                state.currentResource.production.measurementLocations[0] = action.payload;
            }
        },
        setProductionExternalId: (state, action) => {
            if (state.currentResource && state.currentResource.production) {
                state.currentResource.production.externalID = action.payload
            }
        },
        setConsumptionExternalId: (state, action) => {
            if (state.currentResource && state.currentResource.consumption) {
                state.currentResource.consumption.externalID = action.payload
            }
        },
        setProductionSupplier: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource && state.currentResource.production) {
                state.currentResource.production.supplierId = action.payload;
            }
        },
        setConsumption: (state, action: PayloadAction<MarketLocation | undefined>) => {
            if (state.currentResource) {
                state.currentResource.consumption = action.payload
            }
        },
        setConsumptionAccountingGroup: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource && state.currentResource.consumption) {
                state.currentResource.consumption.accountingGroup = action.payload
            }
        },
        setConsumptionTrancheSupplierAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            const { index, value } = action.payload;
            if (state.currentResource && state.currentResource.consumption) {
                if (state.currentResource.consumption.tranches) {
                    state.currentResource.consumption.tranches[index].supplierId = value;
                }
            }
        },
        setConsumptionTranches: (state, action: PayloadAction<EditableTranche[] | Tranche[] | undefined>) => {
            if (state.currentResource && state.currentResource.consumption) {
                if (action.payload && action.payload.length > 0) {
                    state.currentResource.consumption.accountingGroup = undefined;
                    state.currentResource.consumption.supplierId = undefined;
                }
                state.currentResource.consumption.tranches = action.payload;
            }
        },
        updateNewConsumptionTrancheSupplier: (state, action: PayloadAction<{ value: string | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.consumption &&
                state.currentResource.consumption.tranches &&
                state.currentResource.consumption.tranches.length > 0) {
                state.currentResource.consumption.tranches[action.payload.index].supplierId = action.payload.value;
            }
        },
        updateNewConsumptionTrancheExternalID: (state, action: PayloadAction<{ value: string | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.consumption &&
                state.currentResource.consumption.tranches &&
                state.currentResource.consumption.tranches.length > 0) {
                state.currentResource.consumption.tranches[action.payload.index].externalID = action.payload.value;
            }
        },
        updateNewConsumptionTrancheAccountingGroup: (state, action: PayloadAction<{ value: string | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.consumption &&
                state.currentResource.consumption.tranches &&
                state.currentResource.consumption.tranches.length > 0) {
                state.currentResource.consumption.tranches[action.payload.index].accountingGroup = action.payload.value;
            }
        },
        updateNewConsumptionTrancheSizeUnit: (state, action: PayloadAction<{ value: TrancheSizeUnit | undefined, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.consumption &&
                state.currentResource.consumption.tranches &&
                state.currentResource.consumption.tranches.length > 0) {
                state.currentResource.consumption.tranches[action.payload.index].sizeUnit = action.payload.value;
            }
        },
        updateNewConsumptionTrancheSize: (state, action: PayloadAction<{ value: number, index: number }>) => {
            if (state.currentResource &&
                state.currentResource.consumption &&
                state.currentResource.consumption.tranches &&
                state.currentResource.consumption.tranches.length > 0) {
                state.currentResource.consumption.tranches[action.payload.index].size = action.payload.value;
            }
        },
        setConsumptionVoltageLevel: (state, action: PayloadAction<VoltageLevel | undefined>) => {
            if (state.currentResource &&
                state.currentResource.consumption) {
                state.currentResource.consumption.voltageLevel = action.payload;
            }
        },
        setConsumptionVoltageTransformation: (state, action: PayloadAction<VoltageTransformation | undefined>) => {
            if (state.currentResource &&
                state.currentResource.consumption) {
                state.currentResource.consumption.voltageTransformation = action.payload;
            }
        },
        setConsumptionMeasurementLocations: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentResource &&
                state.currentResource.consumption) {
                state.currentResource.consumption.measurementLocations = action.payload;
            }
        },
        updateNewConsumptionMeasurementLocation: (state, action: PayloadAction<string>) => {
            if (state.currentResource && state.currentResource.consumption && state.currentResource.consumption.measurementLocations) {
                state.currentResource.consumption.measurementLocations[0] = action.payload;
            }
        },
        setConsumptionSupplier: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource && state.currentResource.consumption) {
                state.currentResource.consumption.supplierId = action.payload;
            }
        },
        setBillingModel: (state, action: PayloadAction<BillingModel | undefined>) => {
            if (state.currentResource) {
                state.currentResource.billingModel = action.payload;
            }
        },
        setOperator: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource) {
                state.currentResource.operatorId = action.payload;
            }
        },
        setPreliminaryDecommissioningDate: (state, action: PayloadAction<boolean | undefined>) => {
            if (state.currentResource) {
                state.currentResource.preliminaryDecommissioningDate = action.payload;
            }
        },
        setFinalDecommissioningDate: (state, action: PayloadAction<boolean | undefined>) => {
            if (state.currentResource) {
                state.currentResource.finalDecommissioningDate = action.payload;
            }
        },
        setProductionNetRatedCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.productionNetRatedCapacity = action.payload;
            }
        },
        setConsumptionNetRatedCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.consumptionNetRatedCapacity = action.payload;
            }
        },
        setProductionNetBottleneckCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.productionNetBottleneckCapacity = action.payload;
            }
        },
        setConsumptionNetBottleneckCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.consumptionNetBottleneckCapacity = action.payload;
            }
        },
        setGrossRatedCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.grossRatedCapacity = action.payload;
            }
        },
        setAccumulatedInverterPower: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.accumulatedInverterPower = action.payload;
            }
        },
        setReduction70Percent: (state, action: PayloadAction<boolean | undefined>) => {
            if (state.currentResource) {
                state.currentResource.reduction70Percent = action.payload;
            }
        },
        setPlantType: (state, action: PayloadAction<string | undefined>) => {
            if (state.currentResource) {
                state.currentResource.plantType = action.payload;
            }
        },
        setHubHeight: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.hubHeight = action.payload;
            }
        },
        setLongitude: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.longitude = action.payload;
            }
        },
        setLatitude: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.latitude = action.payload;
            }
        },
        setEfficiencyOfEnergyStorage: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.efficiencyOfEnergyStorage = action.payload;
            }
        },
        setUsableEnergyContentOfEnergyStorage: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.usableEnergyContentOfEnergyStorage = action.payload;
            }
        },
        setMaxEffectiveStoringCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.maxEffectiveStoringCapacity = action.payload;
            }
        },
        setMaxEffectiveRetrievingCapacity: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.maxEffectiveRetrievingCapacity = action.payload;
            }
        },
        setAssignedStorages: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentResource) {
                state.currentResource.assignedStorages = action.payload;
            }
        },
        updateNewAssignedStorage: (state, action: PayloadAction<string>) => {
            if (state.currentResource && state.currentResource.assignedStorages) {
                state.currentResource.assignedStorages[0] = action.payload;
            }
        },
        setEegUnitCodes: (state, action: PayloadAction<string[] | undefined>) => {
            if (state.currentResource) {
                state.currentResource.eegUnitCodes = action.payload;
            }
        },
        updateNewEegCode: (state, action: PayloadAction<string>) => {
            if (state.currentResource && state.currentResource.eegUnitCodes) {
                state.currentResource.eegUnitCodes[0] = action.payload;
            }
        },
        setConsumesEnergy: (state, action: PayloadAction<boolean>) => {
            if (state.currentResource) {
                state.currentResource.consumesEnergy = action.payload;
                if (!state.currentResource.consumption && action.payload) {
                    state.currentResource.consumption = {};
                } else if (!action.payload) {
                    state.currentResource.consumption = undefined;
                }
            }
        },
        setProducesEnergy: (state, action: PayloadAction<boolean>) => {
            if (state.currentResource) {
                state.currentResource.producesEnergy = action.payload;
                if (!state.currentResource.production && action.payload) {
                    state.currentResource.production = {};
                } else if (!action.payload) {
                    state.currentResource.production = undefined;
                }
            }
        },
        setIncline: (state, action: PayloadAction<number>) => {
            if (state.currentResource) {
                state.currentResource.incline = action.payload;
            }
        },
        setWindPowerCurveShutdownWindSpeed: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.windPowerCurve = {
                    ...state.currentResource.windPowerCurve,
                    shutdownWindSpeed: action.payload
                }
            }
        },
        setWindPowerCurveNetRatedCapacityWindSpeed: (state, action: PayloadAction<number | undefined>) => {
            if (state.currentResource) {
                state.currentResource.windPowerCurve = {
                    ...state.currentResource.windPowerCurve,
                    netRatedCapacityWindSpeed: action.payload
                }
                const netRatedCapacityWindSpeed = action.payload;

                if (netRatedCapacityWindSpeed !== undefined) {
                    const remainingElements = state.currentResource.windPowerCurve.elements?.slice(0, netRatedCapacityWindSpeed / 0.01);
                    state.currentResource.windPowerCurve.elements = [
                        ...(Array.from(Array(Math.round(netRatedCapacityWindSpeed / WIND_POWER_INCREMENTS)).keys())).map((_) => ({
                            value: undefined,
                            speed: Number.parseFloat(((_ + 1) * WIND_POWER_INCREMENTS).toFixed(2))
                        }))
                    ]
                    remainingElements?.forEach((e, index) => {
                        state.currentResource!.windPowerCurve!.elements![index] = e
                    });
                } else {
                    state.currentResource!.windPowerCurve!.elements = [];
                }
            }
        },
        setWindPowerCurveElementAtIndex: (state, action: PayloadAction<{ value: number, index: number }>) => {
            if (state.currentResource && state.currentResource.windPowerCurve && state.currentResource.windPowerCurve.elements) {
                state.currentResource.windPowerCurve.elements[action.payload.index].value = action.payload.value;
            }
        },
        setDirection: (state, action: PayloadAction<number>) => {
            if (state.currentResource) {
                state.currentResource.direction = action.payload;
            }
        },
        setMissingValueForDirection: (state, action: PayloadAction<boolean>) => {
            if (state.currentResource) {
                state.currentResource.missingValueForDirection = action.payload;
            }
        },
        setMissingValueForIncline: (state, action: PayloadAction<boolean>) => {
            if (state.currentResource) {
                state.currentResource.missingValueForIncline = action.payload;
            }
        },
        incrementProductionRevision: (state) => {
            if (state.currentResource && state.currentResource.production) {
                state.currentResource.production.revision = (state.currentResource.production.revision ?? 0) + 1
            }
        },
        incrementConsumptionRevision: (state) => {
            if (state.currentResource && state.currentResource.consumption) {
                state.currentResource.consumption.revision = (state.currentResource.consumption.revision ?? 0) + 1
            }
        },
        incrementResourceRevision: (state) => {
            if (state.currentResource) {
                if (state.currentResource.revision != null)
                    state.currentResource.revision += 1;
                else
                    state.currentResource.revision = 0;
                if (state.allResources) {
                    const resIdx = state.allResources.findIndex(r => r.inventoryItemId === state.currentResource!.inventoryItemId) ?? 0;
                    state.allResources[resIdx] = state.currentResource;
                }
            }
        },
    },
});

export const {
    setAllResources,
    setCurrentResource,
    setName,
    setExternalID,
    setMarketMasterDataRegistryId,
    setType,
    setPowerPlantCode,
    setProduction,
    setProductionAccountingGroup,
    setProductionTranches,
    updateNewProductionTrancheSupplier,
    updateNewProductionTrancheAccountingGroup,
    updateNewProductionTrancheSizeUnit,
    updateNewProductionTrancheSize,
    setProductionVoltageLevel,
    setProductionVoltageTransformation,
    setProductionMeasurementLocations,
    updateNewProductionMeasurementLocation,
    setProductionExternalId,
    setProductionSupplier,
    setConsumptionAccountingGroup,
    updateNewConsumptionMeasurementLocation,
    updateNewConsumptionTrancheAccountingGroup,
    updateNewConsumptionTrancheSize,
    updateNewConsumptionTrancheSizeUnit,
    setConsumptionSupplier,
    setConsumptionExternalId,
    setConsumptionMeasurementLocations,
    setConsumptionTranches,
    setConsumptionVoltageLevel,
    setConsumptionVoltageTransformation,
    updateNewConsumptionTrancheSupplier,
    setConsumption,
    setBillingModel,
    setOperator,
    setPreliminaryDecommissioningDate,
    setFinalDecommissioningDate,
    setAccumulatedInverterPower,
    setConsumptionNetBottleneckCapacity,
    setConsumptionNetRatedCapacity,
    setEfficiencyOfEnergyStorage,
    setGrossRatedCapacity,
    setHubHeight,
    setLatitude,
    setLongitude,
    setMaxEffectiveRetrievingCapacity,
    setMaxEffectiveStoringCapacity,
    setPlantType,
    setProductionNetBottleneckCapacity,
    setProductionNetRatedCapacity,
    setReduction70Percent,
    setUsableEnergyContentOfEnergyStorage,
    setAssignedStorages,
    setEegUnitCodes,
    updateNewAssignedStorage,
    updateNewEegCode,
    updateNewConsumptionTrancheExternalID,
    updateNewProductionTrancheExternalID,
    setProducesEnergy,
    setConsumesEnergy,
    incrementConsumptionRevision,
    incrementProductionRevision,
    addResourceToArray,
    resetCurrentResource,
    setConsumptionTrancheSupplierAtIndex,
    setProductionTrancheSupplierAtIndex,
    incrementResourceRevision,
    setDirection,
    setIncline,
    setWindPowerCurveElementAtIndex,
    setWindPowerCurveNetRatedCapacityWindSpeed,
    setWindPowerCurveShutdownWindSpeed,
    setMissingValueForDirection,
    setMissingValueForIncline
} = technicalResourcesSlice.actions;

export default technicalResourcesSlice.reducer;
