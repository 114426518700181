import { TechnicalResource } from "../../fixed/TechnicalResource/TechnicalResource";
import { CreateMarketLocationDto, toDto as toCreateMarketLocationDto } from "./CreateMarketLocationDto";

export interface CreateTechnicalResourceDto {
    externalID?: string
    name?: string
    marketMasterDataRegistryId?: string
    technicalResourceType?: string
    powerPlantCode?: string
    assignedStorages?: string[]
    productionId?: string
    consumptionId?: string
    production?: CreateMarketLocationDto
    consumption?: CreateMarketLocationDto
    eegUnitCodes?: string[]
    billingModel?: string
    operatorId?: string
    preliminaryDecommissioningDate?: boolean
    finalDecommissioningDate?: boolean
    productionNetRatedCapacity?: number
    consumptionNetRatedCapacity?: number
    productionNetBottleneckCapacity?: number
    consumptionNetBottleneckCapacity?: number
    grossRatedCapacity?: number
    accumulatedInverterPower?: number
    reduction70Percent?: boolean
    plantType?: string
    hubHeight?: number
    longitude?: number
    latitude?: number
    efficiencyOfEnergyStorage?: number
    usableEnergyContentOfEnergyStorage?: number
    maxEffectiveStoringCapacity?: number
    maxEffectiveRetrievingCapacity?: number
    revision?: number
    incline?: number
    direction?: number
    shutdownWindSpeed?: number
    netRatedCapacityWindSpeed?: number
    windPowerPoints?: {
        speed: number,
        power: number
    }[]
}

export const toDto = (original: TechnicalResource): CreateTechnicalResourceDto => {
    const backup = { ...original }

    delete backup.billingModel
    delete backup.technicalResourceType

    delete backup.production
    delete backup.consumption
    delete backup.windPowerCurve

    delete backup.inventoryItemId
    return {
        ...backup,
        reduction70Percent: original.reduction70Percent === true,
        production: original.production && Object.keys(original.production).length !== 0 ? toCreateMarketLocationDto(original.production) : undefined,
        consumption: original.consumption && Object.keys(original.consumption).length !== 0 ? toCreateMarketLocationDto(original.consumption) : undefined,
        technicalResourceType: original.technicalResourceType?.code,
        billingModel: original.billingModel?.code,
        shutdownWindSpeed: original.windPowerCurve?.shutdownWindSpeed,
        netRatedCapacityWindSpeed: original.windPowerCurve?.netRatedCapacityWindSpeed,
        windPowerPoints: original.windPowerCurve?.elements?.map(v => ({ speed: v.speed, power: v.value ?? 0 }))
    };
}
